import api from "./interceptors"; // Pakai instance API yang sudah ada

const userDetail = async () => {
  return await api.post("detail-user");
};

const logout = async () => {
  return await api.post("logout-account");
};

const register = async (data) => {
  let req = new FormData();
  req.append("email", data.email);
  req.append("name", data.fullName);
  req.append("usr_birthdate", data.usr_birthDate);
  req.append("phone", data.phoneNumber);
  req.append("gender", data.gender);
  req.append("provinsi", data.provinsi1);
  req.append("kabupaten_kota", data.kabupaten1);
  req.append("kecamatan", data.kecamatan1);
  req.append("kelurahan", data.kelurahan1);
  req.append("alamat", data.alamat1);
  req.append("no_mr", data.patientMRNumber);
  req.append("relationship", data.relationshipStatus);
  req.append("psn_birthdate", data.psn_birthDate);
  req.append("password_access", data.password);

  return await api.post("register", req);
};

const editUser = async (req) => {
  let data = new FormData();
  data.append("user_id", req.user_id);
  data.append("name", req.name);
  data.append("date_of_birth", req.date_of_birth);
  data.append("email", req.email);
  data.append("phone", req.phone);
  data.append("gender", req.gender);
  data.append("provinsi", req.provinsi);
  data.append("kabupaten_kota", req.kabupaten_kota);
  data.append("kecamatan", req.kecamatan);
  data.append("kelurahan", req.kelurahan);
  data.append("alamat", req.alamat);
  return await api.post("edit-account-user", data);
};

const generateOtp = async (phone, device_id) => {
  let data = new FormData();
  data.append("phone", phone);
  data.append("device_id", device_id);
  return await api.post("generate-otp", data);
};

const receiveOtp = async (phone, device_id, otp_code) => {
  let data = new FormData();
  data.append("phone", phone);
  data.append("device_id", device_id);
  data.append("otp_code", otp_code);
  return await api.post("receive-otp", data);
};

export default {
  userDetail,
  logout,
  register,
  editUser,
  generateOtp,
  receiveOtp,
};
