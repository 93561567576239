<template>
  <div class="container-splash">
    <div class="splash-screen">
      <div class="splash-content">
        <img :src="require('@/assets/RUMATKITA.png')" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "splash-screen",
};
</script>

<style>
.container-splash {
  width: 100%;
  height: 100vh;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: rgb(244, 244, 244);
}

.splash-screen {
  width: 480px;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.splash-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
}

.splash-content > img {
  width: 100%;
  object-fit: contain;
}

.logo-splash {
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin-bottom: 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
</style>
