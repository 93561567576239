// import storage from "@/controllers/storage";
import userController from "@/controllers/user";
import notificationController from "@/controllers/notification";

export default {
  namespaced: true,
  state: {
    datauser: null,
    notif: [],
  },

  getters: {
    get_res_datauser: (state) => state.datauser,
    get_res_notif: (state) => state.notif,
  },

  mutations: {
    setDataUser(state, datauser) {
      state.datauser = datauser;
    },

    setNotif(state, notif) {
      state.notif = notif;
    },
    clearDataUser(state) {
      state.datauser = null;
    },
  },

  actions: {
    async fetchDataUser({ commit }, token) {
      try {
        const res = await userController.userDetail(token);
        if (res.data.STATUS == 200) {
          commit("setDataUser", res.data.DATA);
        } else {
          if (token) {
            localStorage.clear();
          }
        }
      } catch (error) {
        commit("clearDataUser"); // Clear data if an error occurs
      }
    },

    async fetchNotif({ commit }, request) {
      const { data } = await notificationController.notifications(request.memberId);
      if (data.STATUS == 200) {
        commit("setNotif", data.DATA);
      }
    },
  },
};
