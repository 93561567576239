import axios from "axios";

const api = axios.create({
  baseURL: process.env.VUE_APP_GATEWAY,
  headers: {
    "Content-Type": "application/json",
  },
});

// ✅ Interceptor untuk menambahkan Authorization token ke setiap request
api.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// 🔹 Variabel global untuk menandai proses refresh token
let isRefreshing = false;
let refreshSubscribers = [];

// 🔹 Fungsi untuk menambahkan request yang menunggu token baru
const subscribeTokenRefresh = (callback) => {
  refreshSubscribers.push(callback);
};

// 🔹 Jalankan semua request yang tertunda setelah refresh token berhasil
const onRefreshed = (newToken) => {
  refreshSubscribers.forEach((callback) => callback(newToken));
  refreshSubscribers = [];
};

// ✅ Fungsi untuk melakukan refresh token (hanya 1 kali dalam satu waktu)
const refreshTokenRequest = async () => {
  if (isRefreshing) {
    // Jika sudah dalam proses refresh, kembalikan promise yang menunggu token baru
    return new Promise((resolve) => {
      subscribeTokenRefresh((token) => {
        resolve(token);
      });
    });
  }

  isRefreshing = true; // Tandai bahwa refresh token sedang berjalan

  try {
    const oldToken = localStorage.getItem("token");
    if (!oldToken) {
      console.warn("⚠️ Tidak ada token, tidak bisa refresh");
      return null;
    }

    const response = await axios.post(
      process.env.VUE_APP_GATEWAY + "refresh-token",
      {},
      {
        headers: {
          Authorization: `Bearer ${oldToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.data.status === 200) {
      const newToken = response.data.access_token;
      localStorage.setItem("token", newToken);
      isRefreshing = false; // Tandai bahwa refresh selesai
      onRefreshed(newToken); // Beri tahu request lain bahwa token baru sudah ada
      return newToken;
    } else {
      console.error("❌ Refresh token gagal, status bukan 200");
      isRefreshing = false;
      return null;
    }
  } catch (error) {
    console.error("❌ Gagal refresh token:", error.response?.data || error.message);
    isRefreshing = false;
    return null;
  }
};

// ✅ Interceptor untuk menangani error (termasuk refresh token otomatis)
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const newToken = await refreshTokenRequest();
      if (newToken) {
        originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
        return api(originalRequest); // 🔁 Ulangi request dengan token baru
      } else {
        console.warn("⚠️ Token refresh gagal, akan logout");
        localStorage.clear();
        window.location.href = `${process.env.VUE_APP_URL}home`;
      }
    }

    return Promise.reject(error);
  }
);

export default api;
