<template>
  <div class="alert-popup" v-if="alertData.alertShow">
    <div class="alert-content" v-if="alertData.alertType === 'success'">
      <div class="alert-header">
        <span>{{ alertData.alertTitle }}</span>
      </div>
      <div class="alert-body">
        <p>{{ alertData.alertMessage }}</p>
      </div>
      <div class="list-btn">
        <button class="container-button" @click="closeAlert">OK</button>
      </div>
    </div>
    <div class="alert-content" v-else>
      <div class="alert-header">
        <span class="material-icons">warning</span>
        <span>{{ alertData.alertTitle }}</span>
      </div>
      <div class="alert-body">
        <p>{{ alertData.alertMessage }}</p>
      </div>
      <div class="list-btn">
        <button class="container-button" @click="closeAlert">OK</button>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "AlertPopup",
  props: ["alertData"],
  methods: {
    closeAlert() {
      if (this.alertData.alertAction) {
        router.push(this.alertData.alertAction);
      } else {
        this.$emit("closeAlert");
      }
    },
  },
};
</script>

<style scoped>
@import url("./css/alert-popup.css");
</style>
