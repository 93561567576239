const getLocalStorage = () => {
  return {
    token: localStorage.getItem("token"),
    memberId: localStorage.getItem("memberId"),
  };
};

const setLocalStorage = (data) => {
  localStorage.setItem("token", data.token);
  localStorage.setItem("memberId", data.memberId);
};

const RemoveLocalStorage = (data) => {
  localStorage.removeItem("token", data.token);
  localStorage.removeItem("memberId", data.memberId);
};
export default { getLocalStorage, setLocalStorage, RemoveLocalStorage };
