import Echo from "laravel-echo";
import Pusher from "pusher-js";

window.Pusher = Pusher;

const echo = new Echo({
  broadcaster: "pusher",
  // key: process.env.VUE_APP_PUSHER_KEY_DEV,
  // key: process.env.VUE_APP_PUSHER_KEY_STG,
  key: process.env.VUE_APP_PUSHER_KEY_PROD,
  cluster: process.env.VUE_APP_PUSHER_CLUSTER,
  forceTLS: true,
});

export default echo;
