import api from "./interceptors"; // Gunakan api.js yang sudah dibuat

const notifications = async (memberid) => {
  return await api.get(`get-list-notif/${memberid}`);
};

const readNotif = async (notifid) => {
  return await api.get(`read-notif-user/${notifid}`);
};

export default {
  notifications,
  readNotif,
};
